<template>
  <div align="center">
    <strong>Вы действительно хотите утвердить программу ?</strong>
    <br>В случае утверждения программы все заявки будут не доступны для редактирования.</br>
  </div>
</template>
<script>
export default {
  props: ['model'],
  name: 'ApprovalInvestProgrammsCmp',
  data () {
    return {
      local: this.model
    }
  },
  watch: {
    local () {
      this.model = this.local
    },
    model () {
      this.local = this.model
    }
  }
}
</script>

<style scoped>

</style>

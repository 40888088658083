import BasePlugin from '../BasePlugin'
import ApprovalInvestProgrammsCmp from './ApprovalInvestProgrammsCmp'

export default class ApprovalInvestmentPrograms extends BasePlugin {
  async execute () {
    const h = this.context.$createElement
    let values = this.context.getModel()
    let message = 'Инвестиционная программа уже утверждена!'
    // Проверка статуса инвестпрограммы на утверждение
    if (this.context.getModel().attr_1591_ === 2) {
      this.context.$msgbox({
        type: 'info',
        message: message
      })
      done()
    } else {
      this.context.$msgbox({
        title: 'Утверждение инвестпрограммы',
        message: h('p', { key: Math.random() }, [
          h(ApprovalInvestProgrammsCmp, { props: { model: values } })
        ]),
        showCancelButton: true,
        confirmButtonText: 'Да',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            let data = {
              'investmentProgramId': this.context.getModel().id
            }
            this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/ApprovalInvestmentPrograms`,
              data,
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            )
            done()
            then((response) => {
              let message = 'Инвестиционная программа утверждена.'
              this.context.$message({
                type: 'info',
                message: message
              })
              done()
            })
          } else { done() }
        }
      })
    }
  }
}
